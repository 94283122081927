*{
  margin: 0;
  padding: 0;
  color: whitesmoke;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  --dark: #000000;
  --medium-dark: rgb(40, 40, 40);
  --light: #ffffff;
  --medium-light: #ebebeb;
  --gray: gray;
  --light-gray: lightgray;
  --dark-gray: dimgray;
}
body {
  min-height: 100vh;
  max-width: 100vw;
  background: linear-gradient(-115deg, var(--medium-dark), var(--dark) 50%, var(--medium-dark));
  /* background: linear-gradient(90deg, var(--light), var(--light) 30% 70%, var(--light)); */
}
