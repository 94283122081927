/* NAVBAR */
.nav {
    top: 0px;
    z-index: 10;
    position: sticky;
    backdrop-filter: blur(20px);

    .nav-bar {
        width: 95%;
        height: 8vh;
        display: flex;
        margin-inline: auto;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px solid var(--dark-gray);


        .nav-logo-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 0;
            border-radius: 5px;
            cursor: pointer;
            background-color: var(--medium-light);

            .logo {
                height: 4vh;
                border-radius: 5px;
                margin-inline: 15px;
                transform: rotate(20deg);
            }

            .label {
                margin-right: 20px;
                color: var(--dark);
            }
        }

        .menu {
            display: none;
        }

        .nav-ul {
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .nav-li {
                .nav-link {
                    font-size: 1.1rem;
                    font-weight: 700;
                    padding: 5px 8px;
                    border-radius: 5px;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        color: var(--medium-dark);
                        transform: scale(1.5);
                        background-color: lime;
                        box-shadow: 0 0 5px lime;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .nav-bar {
            position: relative;

            .menu {
                display: flex;
                height: 6vh;
                border-radius: 5px;
                background-color: var(--medium-light);
            }

            .nav-ul {
                right: 0;
                top: calc(8vh - 1px);
                position: absolute;
                flex-direction: column;
                transform: translateX(105%);
                border-left: 2px solid var(--dark-gray);
                border-bottom: 2px solid var(--dark-gray);
                border-bottom-left-radius: 15px;
                background-color: rgba(0, 0, 0, 0.55);
                transition: all .3s ease-in-out;
                &.show {
                    transform: translateX(5vw);
                }
                .nav-li {
                    padding: 5px 0;
                    margin: 5px 0;
                }
            }
        }
    }
}

/* CARD */
.card {
    width: 250px;
    margin: 15px;
    aspect-ratio: 9/12;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--dark-gray);

    .card-img {
        width: 100%;
        aspect-ratio: 9/12;
        border-radius: 10px;

    }

    .card-information {
        top: 100%;
        width: 100%;
        height: 100%;
        padding: 20px;
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.75);
        transition: all 0.3s ease-in-out;

        .card-information-release {
            text-align: end;
            font-weight: bold;
        }

        .card-information-synopsis-container {
            display: flex;
            padding-inline: 5px;
            flex-direction: column;

            .card-information-synopsis-label {
                font-size: 1.5rem;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .card-information-synopsis-description {
                text-align: justify;
                display: -webkit-box;
                /* On précise qu'on veut utiliser webkit-box */
                -webkit-box-orient: vertical;
                /* On indique le sens du texte */
                -webkit-line-clamp: 11;
                /* On donne le nombre de lignes souhaité */
                overflow: hidden;
                /* On cache tout ce qui peut dépasser */
            }
        }
    }

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 10px var(--light);

        .card-information {
            top: 0%;
        }
    }
}


/* PAGE */
.page {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 92vh;
    justify-content: center;
}

.page.detail {
    overflow: hidden;

    .detail-card-poster {
        display: flex;
        position: relative;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .detail-backdrop {
            width: 100%;
            opacity: 0.15;
            position: absolute;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .detail-poster {
            width: 20%;
        }

        .detail-card {
            width: 65%;
            min-height: 70%;
            border-radius: 20px;
            background-color: var(--medium-light);

            .detail-card-title {
                text-align: center;
                font-size: 2rem;
                color: var(--dark);
            }

            .detail-card-title-actors {
                font-size: 1.5rem;
                margin: 5px 25px;
                color: var(--dark);
            }

            .detail-card-actors {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .detail-card-actor {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 5px;
                    padding: 5px 10px;
                    border-radius: 5px;

                    .detail-card-actor-img {
                        height: 60px;
                        aspect-ratio: inherit;
                        border-radius: 5px;
                        margin-right: 5px;
                    }

                    .detail-card-actor-name {
                        display: flex;
                        color: var(--dark);
                        flex-direction: column;

                        span {
                            color: var(--dark);
                        }
                    }
                }

            }
        }
    }

}